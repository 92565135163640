import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SEO from '../components/seo';
import Content from '../components/Content/content';
import Section from '../components/Section/section';

import '../assets/styles/main.scss';

const DatenschutzPage = () => {
	return (
		<Content>
			<SEO title="Lektorat Kasper - Datenschutzerklärung"/>
			<Sections/>
		</Content>
	);
};

export default DatenschutzPage;

const Sections = () => (
	<StaticQuery
		query={
			graphql`
				query DatenschutzSectionsQuery {
					allFile(filter: {
						name: { eq : "datenschutz" }
					}) {
						edges {
							node {
								childDatenschutzJson {
									id
									contents {
										type
										text
									}
								}
							}
						}
					}
				}
	`}
		render={data => (Section(data.allFile.edges[0].node.childDatenschutzJson))}
	/>
);